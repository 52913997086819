.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: white;
    border-radius: 25px;
    font-family: "Avenir Next", sans-serif;
    font-weight: 900;
    font-size: large;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 0.05em;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}