.array-container {
    left: 100px;
}

.array-bar {
    width: 1px;
    background-color: magenta;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1px;
    margin-left: 1px;
}